import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/src/cookieconsent.css'
import {cookieconsentOptions} from "./cookieconsent-option";

export let ccOptions = cookieconsentOptions;

export let cookieconsent;

export function MwCookieConsent(options = ccOptions) {

    console.debug("mw cookie consent: initCookieConsent...");

    cookieconsent = window.initCookieConsent();

    console.debug("mw cookie consent: initialized.");

    cookieconsent.run(options);

    console.debug("mw cookie consent: run (executed)");

    /* Questa funzione apporta personalizzazioni al layout ed alle funzioni stock di cookieconsent */
    function applyPersonalization() {

        if (ccOptions.cookie_policy_url) {
            let ccLinks = document.getElementsByClassName("cc-link");
            for (let i = 0; i < ccLinks.length; i++) {
                ccLinks[i].setAttribute("href", ccOptions.cookie_policy_url);
            }
        }

        /* Il plugin non consente l'aggiunta di button custom quindi dopo che il box viene creato, gli elementi custom vengono
        * aggiunti manualmente negli script sottostanti.*/
        let cookieBox = document.getElementsByClassName("cloud bottom center slide");
        //Se il box dei cookie è stato creato
        if(cookieBox.length > 0) {
            /* Aggiungiamo la "X" per chiudere il box e definiamo una funzione che alla chiusura, accetta solo i cookie necessari (tecnici)
            * e nasconde il box. */
            let titleCont = document.getElementById("c-ttl");
            let closeButtonString = '<button type="button" id="s-c-bn" class="c-c-bn" aria-label="Close"></button>';
            titleCont.insertAdjacentHTML('beforeend',closeButtonString);

            /* Gestione click sul button "X" */
            let closeButton = document.getElementById("s-c-bn");
            closeButton.onclick = function (){
                cookieconsent.accept([]);
                cookieconsent.hide();
            }

            /* Spostiamo il container del titolo e del button di chiusura del box, all'interno del container #cm */
            let mainCont = document.getElementById("cm");
            mainCont.prepend(titleCont);

            /* Aggiungiamo il tasto "Rifiuta tutti" che consente di disattivare tutti i cookie, fatta eccezione per quelli necessari (tecnici) */
            let primaryButton = document.getElementById("c-p-bn");

            let rejectButtonString = '<button type="button" id="c-r-bn" class="c-bn">'+ ccOptions.languages.it.consent_modal.tertiary_btn.text + '</button>';
            let rejectButton = document.createElement('button');
            rejectButton.innerHTML = rejectButtonString;

            primaryButton.parentNode.insertBefore(rejectButton.firstElementChild,primaryButton.nextSibling);

            /* Gestione click su button "Rifiuta tutti" */
            let rejectAllButton = document.getElementById("c-r-bn");
            rejectAllButton.onclick = function (){
                cookieconsent.accept([]);
                cookieconsent.hide();
            }

            /* Gestione cookie se presente parametro in request  */
            let queryString = window.location.search,
                urlParams = new URLSearchParams(queryString),
                ccRjAll = urlParams.get('CCREJECTALL');
            if(ccRjAll == 'true'){
                cookieconsent.accept([]);
                setTimeout(function () {
                    cookieconsent.hide();
                }, 50);
            }

            /* Apertura setting al click sul link "Cookie Center" */
            let showSettingLink = document.getElementById("show-settings");
            if(showSettingLink) {
                showSettingLink.onclick = function () {
                    cookieconsent.showSettings(1);
                }
            } else {
                console.warn("#show-settings not found in dom.")
            }

        } else {
            console.warn("cookieBox not found (.cloud.bottom.center.slide) ")
        }
    }

    if(document.readyState !== 'loading'){
        console.debug("document is already loaded");
        applyPersonalization();
    } else {
        document.addEventListener("DOMContentLoaded", function(event) {
            console.debug("mw cookie consent: dom loaded");
            applyPersonalization();
        });
    }

    return null;
}