export let cookieconsentOptions = {
    cookie_policy_url: location.hostname+'/cookie-policy',
    // 'opt-in', 'opt-out'
    mode: 'opt-in',
    // language
    current_lang : "it",
    // auto display on page load
    autorun: true,
    // cookie name
    cookie_name: 'cc_cookie',
    // 6 months
    cookie_expiration : 182,
    // cookie domain
    cookie_domain: location.hostname,
    // cookie path
    cookie_path: "/",
    // sameSite attribute
    cookie_same_site: "Lax",
    // specify if you want to set a different number of days - before the cookie expires - when the user accepts only the necessary categories
    cookie_necessary_only_expiration: null,
    // enable if you want the value of the cookie to be rfc compliant (it's base64 encoded)
    use_rfc_cookie: false,
    // enable if you don't want the plugin to run when a bot/crawler is detected
    hide_from_bots: false,
    // delay in ms
    delay: 0,
    // path to theme CSS
    theme_css: '',
    // enable if you want to block page navigation until user action
    force_consent: false,
    // enable revisions
    revision: 0,
    // Language auto-detection strategy
    // Null to disable (default),
    // "browser" to get user's browser language or "document" to read value from <html lang="..."> of current page.
    // If language is not defined => use specified current_lang
    auto_language: null,
    // automatically delete cookies when user opts-out of a specific category inside cookie settings
    autoclear_cookies: false,
    // enable if you want to easily manage existing script tags
    page_scripts: true,
    // remove the html cookie tables
    remove_cookie_tables: true,
    languages: {
        it: {
            consent_modal:{
                title: '🍪 &nbsp; Cookie',
                description: 'Questo sito utilizza cookie tecnici necessari alla navigazione e funzionali all’erogazione del servizio. Utilizziamo i cookie anche per personalizzare contenuti ed annunci, per facilitare le interazioni con le nostre funzionalità social e per analizzare il nostro traffico. Puoi esprimere il tuo consenso cliccando su ACCETTA TUTTI oppure personalizzare le tue scelte cliccando su  <a class="cc_link" id="show-settings">Personalizza</a>.Chiudendo il banner, continueranno ad operare i soli cookie tecnici. Per maggiori informazioni sui cookie utilizzati, visualizza la nostra <a aria-label="Cookie policy" class="cc-link" href="#">Cookie Policy completa</a>.',
                primary_btn: {
                    text: 'Accetta tutti',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Personalizza',
                    role: 'settings'                // 'settings' or 'accept_necessary'
                },
                tertiary_btn: {
                    text: 'Rifiuta tutti',
                    role: 'reject_all'
                }
            },
            settings_modal: {
                title: 'Preferenze',
                save_settings_btn: 'Salva preferenze',
                accept_all_btn: 'Accetta tutti',
                reject_all_btn: 'Rifiuta tutti',       // optional, [v.2.5.0 +]
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'},
                    {col5: 'Type'}
                ],
                blocks: [
                    {
                        title: 'Utilizzo dei Cookie',
                        description: 'Questo sito utilizza cookie tecnici necessari alla navigazione e funzionali all’erogazione del servizio. Utilizziamo i cookie anche per personalizzare contenuti ed annunci, per facilitare le interazioni con le nostre funzionalità social e per analizzare il nostro traffico. Per maggiori informazioni sui cookie utilizzati, visualizza la nostra <a aria-label="Cookie policy" class="cc-link" href="#">Cookie Policy completa</a>.'
                    },
                    {
                        title: 'Cookie tecnici',
                        description: 'I cookie necessari contribuiscono a rendere fruibile il sito web abilitandone funzionalità di base quali la navigazione sulle pagine e l\'accesso alle aree protette del sito. Il sito web non è in grado di funzionare correttamente senza questi cookie.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    },
                    {
                        title: 'Cookie analitici',
                        description: 'I cookie statistici sono utilizzati dai proprietari di siti web per comprendere le modalità con cui i visitatori interagiscono con i siti web raccogliendo e riportando informazioni in modo anonimo.',
                        toggle: {
                            value: 'analytics',
                            enabled: true,
                            readonly: true
                        },  //E' possibile definire i cookie specifici seguendo la struttura sottostante
                        cookie_table: [
                            {
                                col1: '^_ga',
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                col5: 'Permanent cookie',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                                col5: 'Permanent cookie'
                            }
                        ]
                    }
                    /* //MARKETING, disabilitati
                    ,{
                        title:  'Cookie marketing',
                        description: 'I cookie di marketing sono utilizzati per tracciare i visitatori attraverso i siti web, al fine di consentire di visualizzare annunci pubblicitari che siano pertinenti e persuasivi per il singolo utente e conseguentemente maggiormente valorizzabili per i pubblicitari e gli inserzionisti terzi.',
                        toggle: {
                            value: 'remarketing',
                            enabled: false,
                            readonly: false
                        }
                    }*/
                    /*
                    ,{
                        title: 'More information',
                        description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="#yourwebsite">contact me</a>.',
                    }*/
                ]
            }
        }
    },
    gui_options: {
        consent_modal : {
            layout : 'cloud',               // box/cloud/bar
            position : 'bottom center',     // bottom/top + left/right/center
            transition: 'slide'             // zoom/slide
        },
        settings_modal : {
            layout : 'box',                 // box/bar
            // position : 'left',           // left/right
            transition: 'slide'             // zoom/slide
        }
    },
    onAccept: function(cookie){
        // do something
    },
    onChange: function(cookie){
        // do something
    },
    onFirstAction: function(){
        // do something
    },

};